// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Import Rails and necessary libraries
// import '@hotwired/turbo-rails'
// import { Application } from '@hotwired/stimulus'
// import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

// Styles and dependencies
import 'src/application.scss'
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery-ui")
require("popper.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Initialize Stimulus
// const application = Application.start()
// const context = require.context("../../controllers", true, /\.js$/)
// application.load(definitionsFromContext(context))

// Make Stimulus globally accessible (optional)
// window.Stimulus = application
